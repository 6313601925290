@import url(http: //fonts.googleapis.com/css?family=Noto+Sans:400,700,400italic,700italic&subset=latin,latin-ext);
.padding-default {
  padding: 150px 0;
}
.pd-top-20 {
  padding-top: 20px;
}
.pd-top-37 {
  padding-top: 37px;
}
.pd-top-40 {
  padding-top: 40px;
}
.pd-top-95 {
  padding-top: 95px;
}
.pd-top-100 {
  padding-top: 100px;
}
.pd-top-150 {
  padding-top: 150px;
}
.pd-bottom-70 {
  padding-bottom: 70px;
}
.pd-bottom-95 {
  padding-bottom: 95px;
}
.pd-bottom-100 {
  padding-bottom: 100px;
}
.pd-bottom-150 {
  padding-bottom: 150px;
}
.mg-top-100 {
  margin-top: 100px;
}
.mg-bottom-70 {
  margin-bottom: 70px;
}
.mg-bottom-50 {
  margin-bottom: 50px;
}
.mg-bottom-100 {
  margin-bottom: 100px;
}
.mg-bottom-150 {
  margin-bottom: 150px;
}
.mg-top-129 {
  margin-top: 129px;
}
.mg-top-89 {
  margin-top: 89px;
}

.mg-bottom-55 {
  margin-bottom: 55px !important;
}

/***
  |----------------------------------------------------------------------------
  | CSS INDEX
  |----------------------------------------------------------------------------

  1.0 General styles
  2.0 Common styles
  3.0 Main navbar
  4.0 Headroom JS styles
  5.0 banner section styles
  6.0 about area styles
  7.0 work section styles
  8.0 video section styles
  9.0 feature-section styles
  10.0 Team section styles
  11.0 price section styles
  12.0 fun fact styles
  13.0 safe section styles
  14.0 blog section styles
  15.0 contact section styles
  16.0 partner section styles
  17.0 page title styles
  18.0 main blog section styles
  19.0 single blog styles
  20.0 widget styles
  21.0 footer section styles
  22.0 map section styles
  23.0 home 2 styles

***/

/* ==================================================================
  1.0 General styles
================================================================== */

#root {
  white-space: pre-line;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
  overflow: visible !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background: rgb(18, 22, 28); */
  background: #ccc;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(18, 22, 28);
}

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  color: #fff;
  background-color: #fff !important;
}
/* p {
  font-family: "PT Sans", sans-serif !important;
  font-size: 14px !important;
  color: #606060 !important;
  line-height: 26px;
} */
button:focus {
  outline: none !important;
}

a:active,
a:focus,
a:hover {
  text-decoration: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans', sans-serif;
  margin-top: 0;
  /* color: #313131; */
}
.btn {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
}
.btn:hover,
.btn:active,
.btn:focus {
  outline: 0;
  border: 0;
  box-shadow: none !important;
}
.btn-red {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
  background-image: linear-gradient(
    90deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
}
.btn.btn-white {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
  background: #fff;
  color: #ff4081;
}
.btn.btn-light-red {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
  background: #ff4081;
}
.btn.btn-white-shadow {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15) !important;
  color: #ff4081;
}
.btn.btn-white-shadow:hover,
.btn.btn-white-shadow:active,
.btn.btn-white-shadow:focus {
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15) !important;
}
.btn.btn-white-shadow-2 {
  border: 0;
  border-radius: 35px;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
  color: #ff4081;
}
.btn-white-shadow-2:hover,
.btn-white-shadow-2:active,
.btn-white-shadow-2:focus {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1) !important;
}

.sign-in-btn:hover .btn,
.sign-in-btn:active .btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
}
.sign-in-btn {
  margin-left: 15px !important;
  box-shadow: none !important;
  border: none;
}
.sign-in-btn .btn {
  padding: 10px 40px !important;
  color: #fff !important;
  box-shadow: none !important;
  border-radius: 25px;
  border: none;
}
.sign-in-btn.active {
  border-radius: 35px;
}
.border-top-1px {
  border-top: 1px solid #f6f6f6;
}
.border-bottom-1px {
  border-bottom: 1px solid #f6f6f6;
}

/* ==================================================================
   2.0 Common styles
================================================================== */
.section-title {
  margin-bottom: 50px;
}
.section-title .title {
  font-size: 36px;
  color: #fff;
}
.section-title .sub-title {
  color: #ff4081;
  position: relative;
  font-size: 18px;
  padding: 5px;
}
.section-title .sub-title:after {
  background-image: linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  background-image: linear-gradient(
    90deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  background-image: linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  content: '';
  height: 2px;
  width: 50px;
  position: absolute;
  left: 50%;
  bottom: -10px;
  margin-left: -25px;
}

.section-title-2 {
  margin-bottom: 75px;
}
.section-title-2 .title {
  color: #313131;
  margin-bottom: 17px;
}
.section-title-2 .sub-title {
  font-family: 'Noto Sans', sans-serif;
}

/* ==================================================================
   3.0 Main navbar
================================================================== */
.header-top-bar {
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  color: white;
  padding: 10px 0;
}
.header-top-bar .email {
  padding-left: 4px;
  padding-right: 36px;
  position: relative;
}
.header-top-bar .email:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 15px;
  right: 16px;
  top: 2px;
  background: #fff;
}
.header-top-bar .phone-num {
  padding-right: 6px;
}
.header-top-bar .social-area {
  text-align: right;
}
.header-top-bar .social-area span {
  padding-right: 10px;
}

.main-menu ul {
  list-style: none;
  text-align: center;
}
.main-menu ul li {
  display: inline-block;
  padding: 0 13px;
  padding: 7px 20px;
  border-radius: 4px;
}
.main-menu ul li a {
  font-size: 16px;
}
.main-menu ul li:hover {
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15);
}
.nav-bar {
  padding: 20px 0;
}
.nav-bar .main-menu {
  text-transform: capitalize;
  padding-top: 15px;
}
.sing-in-area {
  float: right;
}
.sing-in-area .btn {
  margin-top: 13px;
}
.header-area .social-area a {
  color: #fff;
}
.header-area .social-area i {
  margin: 0 5px;
}

/** header area **/
.header-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

/* nav main*/
.navbar.navbar-default {
  margin-bottom: 0;
  min-height: 90px;
  border: 0;
  background: #fff;
  border-radius: 0;
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15);
  transition: all 0.8s ease;
}
.navbar.navbar-default-2 {
  /*position: fixed;*/
  width: 100%;
  z-index: 1100;
}
.textBlue {
  color: #05c0a5;
  text-decoration: none;
}
.navbar.navbar-default .navbar-brand {
  float: left;
  padding: 0 15px;
  font-size: 18px;
  line-height: 20px;
}
.navbar.navbar-default .navbar-brand {
  position: relative;
  font-size: 60px;
  color: #ffffff;
  font-weight: 500;
}
.navbar.navbar-default .navbar-toggle {
  border-color: #ff500d;
  border-radius: 0;
}

.navbar.navbar-default .navbar-toggle .icon-bar {
  background: #ff500d;
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: 100% !important;
}

@media all and (min-width: 991px) {
  .navbar.navbar-default .navbar-brand {
    padding-top: 18px;
  }
  .navbar.navbar-default.navbar-fixed-top .navbar-brand {
    padding-top: 0;
  }
  .navbar.navbar-default ul.nav.navbar-nav {
    line-height: 91px;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li {
    margin-bottom: 0;
    margin-right: 8px;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li:last-child {
    margin-right: 0;
  }
  .navbar.navbar-default ul.nav.navbar-nav li a {
    display: inline-block;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    padding: 10px 20px;
    font-family: 'Open Sans', sans-serif;
    line-height: 70px;
  }
  .navbar.navbar-default ul.nav.navbar-nav li.menu-item-has-children {
    position: relative;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu {
    position: absolute;
    min-width: 180px;
    top: 100%;
    left: 0;
    background: #3f5267;
    list-style: none;
    padding-left: 0;
    line-height: 25px;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: all 0.3s ease-in-out;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li {
    border-bottom: 1px solid #4b5c70;
    margin-bottom: 0;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li:last-child {
    border-bottom: none;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li
    a {
    padding: 8px 15px;
    color: #fff;
    line-height: 24px;
    width: 100%;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li
    a:hover {
    padding: 8px 15px;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li
    ul.sub-menu {
    margin-left: 30px;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children
    > ul.sub-menu
    li
    ul.sub-menu
    li
    ul.sub-menu
    li
    ul.sub-menu {
    margin-left: -30px;
  }
  .navbar.navbar-default
    ul.nav.navbar-nav
    li.menu-item-has-children:hover
    > ul.sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0);
    transform: rotateX(0);
  }
  .navbar.navbar-default.navbar-fixed-top {
    background: transparent;
    padding-top: 50px;
    transition: all 0.8s ease-in-out;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li.active {
    background: #ffffff;
    color: #ff4081;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li.active > a {
    background: #ffffff;
    color: #ff4081;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li.sign-in-btn a {
    line-height: 22px;
    border: 0;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li {
    transition: all 0.3s ease;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li:hover {
    color: #ff4081;
  }
  .navbar.navbar-default ul.nav.navbar-nav > li:hover a {
    color: #ff4081;
  }
  .navbar.navbar-default.navbar-fixed-top.headroom--not-top
    ul.nav.navbar-nav
    li.active:after {
    background: #d72f5f;
  }
  .navbar.navbar-default.navbar-fixed-top
    ul.nav.navbar-nav
    li
    ul.sub-menu
    li
    a {
    color: #606060;
  }
  /* ==================================================================
    4.0 Headroom JS styles
    ================================================================== */
  .headroom,
  .navbar-home.fixed-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: all 0.2s ease-in-out;
  }
  .headroom--unpinned {
    top: -100px;
  }
  .headroom--pinned {
    top: 0;
  }
  @-webkit-keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }
  @keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }
  .animated.slideDown {
    -webkit-animation-name: slideDown;
    animation-name: slideDown;
  }
  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
    }
  }
  @keyframes slideUp {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
    }
  }
  .animated.slideUp {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
  }
  .headroom--not-top {
    background: #ffffff !important;
    padding: 0;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.15);
  }
  .navbar.navbar-default.navbar-fixed-top.headroom--not-top {
    background: #ffffff !important;
    padding-top: 25px;
  }
  .navbar.navbar-default.navbar-fixed-top.headroom--not-top .navbar-brand {
    color: #ff500d;
  }
  .navbar.navbar-default.navbar-fixed-top.headroom--not-top
    .navbar-brand:before {
    content: none;
  }
  .navbar.navbar-default.navbar-fixed-top.headroom--not-top
    ul.nav.navbar-nav
    li
    a {
    color: #606060;
  }
  .navbar-home.fixed-nav {
    padding: 0 0;
    z-index: 99999;
    display: none;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.8) !important;
  }
}

@media all and (max-width: 991px) {
  /**
    * Change bootstrap navbar collapse breakpoint
    */
  .navbar {
    padding: 20px 10px;
  }
  .navbar.navbar-default .navbar-brand {
    padding: 0;
  }
  .navbar-nav {
    padding-top: 25px;
  }
  .navbar-toggler:focus,
  .navbar-toggler:hover {
    outline: none;
  }
  .navbar-light .navbar-toggler.collapsed {
    background: #ff4081;
  }
  /** navbar click events **/
  ul.nav.navbar-nav li {
    margin-bottom: 0;
  }
  ul.nav.navbar-nav li a {
    display: block;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #171717;
  }

  ul.nav.navbar-nav li ul.sub-menu {
    list-style: none;
    margin-left: 30px;
    padding-left: 0 !important;
  }
  ul.nav.navbar-nav li ul.sub-menu li a {
    display: block;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    color: #171717;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  ul.nav.navbar-nav li.sign-in-btn {
    margin-left: 0 !important;
  }
}

/* ==================================================================
   5.0 banner section styles
================================================================== */
.banner-area .entry-title {
  margin-bottom: 35px;
  color: #fff;
  font-size: 48px;
  line-height: 60px;
}
.banner-area .entry-sub-title {
  margin-bottom: 52px;
  color: #fff;
  font-size: 24px;
}

.banner-area .token-title {
  margin-bottom: 10px;
  color: #000;
  margin-top: 20px;
}

.banner-area .btn {
  /* margin: 0 10px; */
}

/* ==================================================================
   6.0 about area styles
================================================================== */
/* .about-area-bg {
  background: url('../images/image2.png');
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: 100%;
  padding-top: 146px;
} */

.about-area {
  padding-top: 20px;
}
.about-area .entry-title {
  margin-bottom: 27px;
}
.about-area h3 {
  font-size: 24px;
}
.about-area p {
  margin-bottom: 30px;
}
.about-video {
  background: url(/static/media/about_video.20552816.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 150px 0 150px 0;
  background-position: 100%;
  border-radius: 5px;
  text-align: center;
}

/* ==================================================================
   7.0 work section styles
================================================================== */
.work-section {
  padding-top: 146px;
}
.single-element {
  text-align: center;
  padding: 36px 35px 22px 35px;
  margin-bottom: 50px;
  transition: all 0.5s ease-in-out;
  border-radius: 6px;
  border: 1px solid #eee;
  height: 440px;
}

.single-element .description {
  height: 100px;
}
.single-element:hover {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
}
.single-element img {
  margin-bottom: 34px;
}

.single-element h4 {
  margin-bottom: 34px;
}
.single-element .entry-title {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  height: 50px;
}
.single-element .entry-title a {
  color: #313131;
  font-weight: 600;
}

.btn.btn-work {
  background-color: #fd8853;
  color: #fff;
  width: 100%;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  margin-top: 8px;
}

/* ==================================================================
   8.0 video section styles
================================================================== */
/* .video-section {
  position: relative;
  background: url("../images/bg/video_two.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
} */
.video-section::before {
  content: '';
  display: block;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.btn-video {
  position: relative;
  text-align: center;
  display: inline-block;
  color: #fff;
  font-size: 22px;
  width: 60px;
  height: 60px;
  border: 0;
  line-height: 60px;
  border-radius: 50%;
  background: rgba(255, 64, 129, 0.2);
  z-index: 9;
}
.btn-video:focus {
  outline: 0;
}
.btn-video i {
  padding-left: 6px;
  color: #ffffff;
}
.btn-video::before {
  content: '';
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ff4081;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: -1;
}
.video-section .btn-video::before {
  background: #fff;
}
.video-section .btn-video svg {
  font-size: 20px;
  margin-right: -5px;
  color: #1a0d09;
}
.video-section {
  padding: 100px 0 90px 0;
  color: #fff;
}
.video-section .title {
  color: #fff;
  font-size: 48px;
  margin-bottom: 19px;
}
.video-section .btn-video {
  background: #1a0d09;
  margin-bottom: 45px;
}
.video-section p.text {
  color: #fff !important;
}
@media all and (max-width: 768px) {
  .video-section p.text br {
    display: none;
  }
}

/* ==================================================================
   9.0 feature-section styles
================================================================== */
.feature-section {
  padding-top: 143px;
  padding-bottom: 102px;
}
.feature-section .sub-title {
  margin-bottom: 20px;
}
.feature-section .single-element .thumb {
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15);
  height: 60px;
  width: 60px;
  display: inline-block;
  text-align: center;
  margin-bottom: 30px;
  padding-top: 15px;
  border-radius: 50%;
}

/* ==================================================================
   10.0 Team section styles
================================================================== */
/* .team-section {
  background: url('../images/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 95px 0 50px 0;
  background-position: 78%;
} */

.team-single {
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 50px;
  /* height: 400px; */
}
.team-single .entry-content {
  padding: 23px 10px 15px 10px;
  height: 110px;
}
.team-single .entry-thumbnail img {
  width: 100%;
}
.team-single .entry-content .entry-title a {
  color: #313131;
}
.team-single .entry-social {
  height: 115px;
  width: 115px;
  left: 50%;
  margin-left: -57.5px;
  position: absolute;
  top: 0;
  margin-top: 80px;
  padding: 18px 0 0 18px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
.team-single .entry-social:after {
  background-image: linear-gradient(
    to top right,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  opacity: 0;
  position: absolute;
  content: '';
  height: 115px;
  width: 115px;
  left: 0;
  top: 0;
  border-radius: 5px;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}
.team-single .entry-social span {
  height: 25px;
  width: 25px;
  background: #fff;
  display: inline-block;
  color: #ff4081;
  float: left;
  z-index: 10;
  position: relative;
  margin: 7px;
  -webkit-transform: rotate(-48deg) !important;
          transform: rotate(-48deg) !important;
  border-radius: 50%;
}
.team-single .entry-social span svg {
  color: #ff4081;
  font-size: 14px;
}
.team-single .entry-thumbnail {
  position: relative;
}
.team-single .entry-title {
  font-size: 24px;
}
.team-overlay {
  position: absolute;
  content: '';
  background-image: -ms-linear-gradient(
    45deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
  border-radius: 4px;
}
.team-single:hover .entry-social,
.team-single:hover .entry-social:after,
.team-single:hover .team-overlay {
  visibility: visible;
  opacity: 1;
}
.team-single:hover .team-overlay {
  visibility: visible;
  opacity: 0.6;
}
.team-single:hover .entry-social {
  -webkit-transform: rotate(48deg) !important;
          transform: rotate(48deg) !important;
}

/* ==================================================================
   11.0 price section styles
================================================================== */
.pricing-section {
  padding-top: 143px;
  padding-bottom: 100px;
}
.pricing-section .section-title-2 .sub-title {
  color: #606060;
  font-size: 18px;
  line-height: 26px;
  width: 85%;
  margin: 0 auto;
}
.price-table {
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.05);
  padding: 36px 20px 40px 20px;
  margin-bottom: 50px;
  transition: all 0.3s ease-in-out;
}
.price-table:hover {
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
}
.price-table .btn {
  color: #333;
}
.price-table:hover .btn {
  background: #ff4081;
  color: #fff;
}
.price-table .btn:hover {
  color: #fff !important;
}
.price-table .bit-info {
  margin-bottom: 13px;
  font-size: 24px;
}
.price-table span {
  font-size: 18px;
  font-family: 'Noto Sans', sans-serif;
}
.price-table .price {
  margin-bottom: 30px;
  color: #313131;
  font-size: 36px;
  padding-top: 5px;
  font-family: 'PT Sans', sans-serif;
}
.price-table .price svg {
  width: 18px;
}

/* ==================================================================
   12.0 fun fact styles
================================================================== */
/* .factor-section {
  background: url('../images/bg/02.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0 40px 0;
} */
.single-fact {
  margin-bottom: 57px;
}
.single-fact .icon {
  margin-bottom: 25px;
}
.single-fact .title {
  margin-bottom: 21px;
  color: #fff;
  font-family: 'PT Sans', sans-serif;
}
.single-fact .sub-title {
  font-size: 20px;
  color: #fff;
}

/* ==================================================================
   13.0 safe section styles
================================================================== */
.safe-section {
  margin-bottom: 76px;
  padding-top: 150px;
}
.safe-section .safe-factor {
  margin-bottom: 70px;
  text-align: center;
}
.safe-section .safe-factor .icon {
  margin-bottom: 34px;
}
.safe-section .safe-factor .safe-factor-details {
  padding: 0 25px;
}
.safe-section .safe-factor .title {
  margin-bottom: 27px;
  font-size: 18px;
  font-weight: 600;
}
.safe-section .safe-factor .content {
  font-size: 14px;
}

.safe-factor-1 {
  position: relative;
}
/* .safe-factor-1:after {
  background: url('../images/1.png');
  position: absolute;
  content: '';
  height: 19px;
  width: 170px;
  right: -102px;
  top: 50px;
} */
/* .safe-factor-2:after {
  background: url('../images/2.png');
  position: absolute;
  content: '';
  height: 19px;
  width: 170px;
  right: -86px;
  top: 74px;
} */
@media all and (max-width: 768px) {
  .safe-factor-1:after,
  .safe-factor-2:after {
    display: none;
  }
}
/* ==================================================================
   14.0 blog section styles
================================================================== */
/* .blog-section {
  background: url('../images/background2.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 145px 0;
  background-position: 95%;
} */
.blog-section .section-title.title {
  padding-bottom: 21px;
}
.blog-section .blog-post {
  background: #fff;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.05);
  transition: all 0.3s ease;
}
.blog-section .blog-post:hover {
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
}
.blog-section .blog-post img {
  width: 100%;
}

.blog-section .blog-post .entry-thumbnail {
  height: 220px;
}
.blog-section .blog-post .post-details {
  padding: 32px 25px 35px 25px;
}
.blog-section .blog-post .post-details .title {
  font-size: 24px;
  margin-bottom: 12px;
}
.blog-section .blog-post .post-details .title a {
  color: #313131;
}
.blog-section .blog-post .entry-meta {
  padding-bottom: 28px;
}
.blog-section .blog-post .entry-meta span {
  color: #a1a1a1;
  padding: 0 6px;
}
.blog-section .blog-post .entry-meta span a {
  color: #a1a1a1;
}
.blog-section .blog-post .entry-meta span svg {
  margin-right: 5px;
}
.blog-section .blog-post .btn-white-shadow:hover {
  background-color: #ff4484;
  color: #fff !important;
}
.blog-section .blog-post .entry-meta span i {
  padding-right: 8px;
}
.blog-section .blog-post p {
  padding: 0 20px 31px 0;
}
@media all and (max-width: 768px) {
  .blog-section .blog-post {
    margin-bottom: 30px;
  }
}
/* ==================================================================
   15.0 contact section styles
================================================================== */
.contact-section {
  padding-top: 145px;
  padding-bottom: 135px;
}
.contact-section .section-title-2 .sub-title {
  color: #606060;
  font-size: 18px;
  line-height: 26px;
  font-family: 'PT Sans', sans-serif;
  width: 80%;
  margin: 0 auto;
}
.contact-section .address-sec .title {
  font-size: 24px;
  margin-bottom: 26px;
}
.contact-section .address-sec .home {
  margin-bottom: 29px;
  font-size: 14px;
}
.address-sec .phone {
  margin-bottom: 10px;
}
.address-sec .phone svg {
  font-size: 14px;
  margin-right: 14px;
}
.address-sec .email {
  margin-bottom: 30px;
}
.address-sec .email svg {
  font-size: 14px;
  margin-right: 15px;
}
.address-sec .btn svg {
  color: #ff4484;
  display: initial;
  margin-left: 10px;
}
.contact-form .form-control {
  border: none;
  resize: none;
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
  margin-bottom: 35px;
  color: #a1a1a1;
  padding: 10px 15px;
}
.contact-form .form-control.input-control {
  height: 45px;
}
.contact-form .form-group.has-error {
  position: relative;
}
.contact-form .help-block.with-errors {
  position: absolute;
  top: 100%;
  left: 0;
}
.contact-form .form-control.input-control:focus,
.has-error .form-control:focus {
  box-shadow: 0px 0px 40px 0px rgba(249, 69, 121, 0.1);
}
.text-danger {
  text-align: left !important;
}
.contact-form button:hover,
.contact-form button:focus,
.contact-form button:active {
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15) !important;
  outline: 0 !important;
}
.contact-form .btn.btn-white-shadow {
  padding: 12px 40px;
  outline: 0 !important;
}
.map-popup-modal .modal-body {
  position: relative;
  margin-top: 55px;
}
#map-canvas {
  height: 500px;
  width: 100%;
  background: gray;
}
.map-popup-modal .btn-close {
  position: absolute;
  top: -15px;
  right: 0;
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  color: #ffffff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 0;
}
.map-popup-modal .btn-close:focus {
  outline: 0;
}
@media all and (max-width: 768px) {
  .contact-section .section-title-2 .sub-title {
    width: 100%;
  }
}
/* ==================================================================
   16.0 partner section styles
================================================================== */
.partner-section {
  padding-bottom: 138px;
}
.partner-section .logo-area {
  border: 1px solid #cccccc;
  border-radius: 6px;
  text-align: center;
  padding: 15px 40px;
  margin: 1px 15px;
}

/* ==================================================================
   17.0 page title styles
================================================================== */
/* .page-title {
  padding-top: 200px;
  padding-bottom: 70px;
  background: url('../images/blog-image1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
} */
.page-title h2 {
  color: #fff;
  font-size: 60px;
}
.page-title .breadcrumb {
  display: inline-flex;
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: transparent;
  border-radius: 4px;
}
.page-title .breadcrumb a {
  color: #fff;
  font-size: 14px;
}
.page-title .breadcrumb .breadcrumb-item.active {
  color: #fff;
}
.page-title .breadcrumb > li + li:before {
  color: #fff;
}
.page-title {
  margin-bottom: 100px;
}

/* ==================================================================
   18.0 main blog section styles
================================================================== */
.single-post {
  border-radius: 6px;
  box-shadow: 1.5px 2.598px 20px 8px rgba(255, 64, 129, 0.05);
  margin-bottom: 35px;
}
.single-post .entry-thum img {
  max-width: 100%;
}
.single-post .post-details {
  padding: 31px 35px 35px 35px;
}
.single-post .entry-title a {
  font-size: 24px;
  color: #313131;
}
.single-post .meta-post {
  margin-bottom: 12px;
}
.single-post p {
  margin-bottom: 35px;
}
.single-post .post-details .btn.btn-white-shadow:hover {
  background: #ff4484;
  color: #ffffff;
}
.meta-post .meta-tag {
  list-style: none;
  margin: 0px 10px 10px 0;
  padding: 0 0 10px 0;
  display: flex;
  color: #a1a1a1;
  text-align: center;
}
.meta-post .meta-tag a {
  color: #a1a1a1;
}
.meta-post .meta-tag li svg {
  margin-right: 7px;
}
.meta-post .meta-tag span {
  margin: 0 12px;
}
.blog-section .meta-tag .fa {
  padding-right: 5px;
}
.page-navigation.pagination {
  display: inline-flex;
}
.page-navigation.pagination li.page-item a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  color: black;
  margin-right: 10px;
  transition: 0.3s;
}
.page-navigation.pagination li:first-child a,
.page-navigation.pagination li:last-child a {
  border: none;
  background: none;
}
.page-navigation.pagination li.page-item a:active,
.page-navigation.pagination li.page-item a:focus,
.page-navigation.pagination li.page-item a:hover {
  background: none;
  box-shadow: none;
  border-color: none;
}
.page-navigation.pagination li.page-item a:active,
.page-navigation.pagination li.page-item a:focus,
.page-navigation.pagination li.page-item a:hover {
  background: none;
  box-shadow: 0.5px 1px 18px 3px rgba(255, 64, 129, 0.1);
  border-color: transparent;
  color: #ff4081;
}

/* ==================================================================
   19.0 single blog styles
================================================================== */
.single-blog-section .post-thumb {
  margin-bottom: 28px;
}
.single-blog-section .post-thumb img {
  max-width: 100%;
}
.meta-tag span {
  padding: 0 10px;
}
.single-blog-section .entry-content p {
  margin-bottom: 30px;
  font-size: 14px;
}
.single-blog-section .entry-content blockquote {
  margin: 30px 0;
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 20px 0px rgba(255, 64, 129, 0.1);
  padding-left: 20px;
  border-left: 5px solid #ff4081;
}
.single-blog-section .entry-content blockquote p {
  margin-bottom: 0;
}

.single-blog-section .blog-post-share .title {
  padding-right: 20px;
  font-size: 14px;
  color: #313131;
}
.single-blog-section .post-share-tag {
  margin-bottom: 50px;
}
.single-blog-section .blog-post-share a {
  padding: 0 6px;
}
.single-blog-section .blog-post-share svg {
  color: #a1a1a1;
  cursor: pointer;
}
.single-blog-section .author-area .author-social.right a:hover i,
.single-blog-section .blog-post-share i:hover {
  color: #ff4081;
}
.single-blog-section .tag {
  float: right;
  display: flex;
}
.single-blog-section .tag .title {
  padding-right: 10px;
  font-size: 14px;
}
.single-blog-section .tag a {
  color: #a1a1a1;
  font-size: 14px;
  padding-right: 5px;
}
.single-blog-section .tag a:hover {
  color: #ff4081;
}
.single-blog-section .comment-check {
  padding: 50px 0;
}
.single-blog-section .comment-check .right,
.single-blog-section .comment-check .left {
  position: relative;
  text-transform: capitalize;
}
.single-blog-section .comment-check .right:hover,
.single-blog-section .comment-check .left:hover {
  color: #ff4081;
  border-color: #ff4081;
}
.single-blog-section .comment-check .left {
  color: #a1a1a1;
}
.single-blog-section .comment-check .right {
  float: right;
  color: #a1a1a1;
}
.single-blog-section .comments-area .media .comment-reply {
  padding-bottom: 15px;
}
.single-blog-section .comments-area .media a {
  color: #ff4081;
  padding-left: 1px;
}
.single-blog-section .comments-area .media a i {
  margin-right: 5px;
}
.single-blog-section .author-area .author-social.right {
  list-style: none;
  display: flex;
  float: right;
}
.single-blog-section .author-area .media {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 25px 0px rgba(255, 64, 129, 0.05);
  padding: 30px 40px 30px 30px;
}
.single-blog-section .author-area .media .media-left {
  padding-right: 30px;
}

.media .author-social.right i {
  padding: 0 10px;
  display: inline-block;
  color: #a1a1a1;
}
.single-blog-section .author-area .media-body .author-name {
  font-size: 18px;
}
.single-blog-section .media .author-designation {
  color: #a1a1a1;
  font-size: 10px;
  margin-bottom: 10px;
}
.single-blog-section .author-area {
  margin-bottom: 100px;
}
.single-blog-section .comments-area {
  margin-bottom: 100px;
}
.single-blog-section .comments-area .contact-form {
  margin-top: 50px;
}
.single-blog-section .comments-area .comment-title {
  margin-bottom: 40px;
}
.single-blog-section .comments-form .form-control {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 25px 0px rgba(255, 64, 129, 0.1);
  border-style: none;
}
.single-blog-section .comments-form .btn-light-red {
  margin: 50px 0 100px 0;
}

.partner-slider .logo-area {
  border: 1px solid #cccccc;
  border-radius: 6px;
  text-align: center;
  margin: 15px;
}
.partner-slider .logo-area img {
  display: inline-block;
}
@media all and (max-width: 480px) {
  .partner-slider .logo-area {
    margin: 5px;
  }
  .meta-tag span {
    padding: 0;
  }
}
/* ==================================================================
   20.0 widget styles
================================================================== */
.widget_search i {
  color: #ff4081;
}
.widget_search .form-group {
  height: 45px;
  width: 100%;
}
.widget_search .form-group input {
  height: 35px;
  border-radius: 4px;
  border: none;
  box-shadow: none;
}
.widget_search .input-group-addon {
  background-color: #fff !important;
  border: none;
  padding: 5px 15px;
  border-left: 1px solid #d1d1d1;
  cursor: pointer;
}
.widget_search .input-group-addon svg {
  color: #ff4081;
}
.widget_search {
  margin-bottom: 40px;
}
.widget_search .input-group {
  border-radius: 6px;
  padding: 8px 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1.5px 2.598px 20px 8px rgba(255, 64, 129, 0.04);
  width: 100%;
}
.widget-title .title {
  margin-bottom: 50px;
  position: relative;
  font-size: 24px;
}
.widget-title .title:after {
  content: '';
  height: 1px;
  width: 50px;
  position: absolute;
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  left: 0;
  bottom: -20px;
}
.widget_catagory .catagory-items a {
  color: #7a7a7a;
  position: relative;
}
.widget_catagory .catagory-items {
  position: relative;
  list-style: none;
  padding-left: 0px;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 40px;
}
.widget_recent-post {
  margin-bottom: 50px;
}
.widget_recent-post .media {
  margin-bottom: 30px;
}
.widget_recent-post .media .title {
  margin-bottom: 8px;
  font-size: 17px;
  line-height: 20px;
}
.widget_recent-post .media .media-body {
  margin-left: 15px;
}
.widget_recent-post .media .title a {
  color: #313131;
}
.widget_tag .tag-list a {
  border: 1px solid #a1a1a1;
  padding: 5px 14px 6px 14px;
  display: inline-block;
  margin: 3px 3px;
  font-size: 12px;
  color: #524f4f;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.widget_tag .tag-list a:hover {
  border: 1px solid #ff4484;
  color: #ff4484;
}

/* ==================================================================
   21.0 footer section styles
================================================================== */
.footer-section {
  /* background: url('../images/bg/footer-bg.png'); */
  padding: 30px 0 0px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100%;
  background-color: #041562;
  color: #fff;
  /* position: absolute; */
  bottom: 0px;
  width: 100%;
  /* height: 200px; */
}
.footer-section .widget_recent-post .title a {
  color: #fff;
}
.footer-section .widget-title .title {
  color: #fff;
}
.footer-section .widget-about {
  margin-bottom: 50px;
}
.widget-about .footer-logo {
  margin-bottom: 35px;
}
.widget-about .content {
  color: #fff !important;
  margin-bottom: 35px;
}
.widget-about .social-area a {
  display: inline-block;
  height: 35px;
  width: 35px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  line-height: 36px;
  margin-right: 8px;
}
.footer-title {
  color: #fff;
  position: relative;
  margin-bottom: 39px;
}
.footer-title:after {
  content: '';
  position: absolute;
  height: 1px;
  width: 40px;
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  left: 0;
  bottom: -13px;
}
.footer-widget-recent-post {
  padding: 0 28px;
}
.footer-widget-recent-post .title {
  color: #fff !important;
  margin-bottom: 5px;
  font-size: 16px;
}
.footer-widget-recent-post .title a {
  display: inline-block;
  color: #fff !important;
}
.footer-section .widget_recent-post p {
  color: #fff !important;
}
.footer-widget-recent-post .media-left {
  margin-right: 15px;
}
.footer-widget-recent-post .media img {
  width: 80px;
}
.widget-about .social-area {
  cursor: pointer;
}
.widget-about .social-area .fa-facebook {
  color: #4867aa;
}
.widget-about .social-area .fa-twitter {
  color: #1da1f2;
}
.widget-about .social-area .fa-pinterest-p {
  color: #d76b76;
}
.widget-about .social-area .fa-linkedin {
  color: #0077b5;
}
.widget-subscribe .title {
  font-size: 24px;
  color: #fff !important;
  margin-bottom: 40px;
}
.widget-subscribe .content {
  font-size: 14px;
  color: #fff !important;
  margin-bottom: 33px;
}

.form-group {
  width: 100%;
  border-radius: 50px;
  background: #ffffff;
  margin-bottom: 40px;
  height: 45px;
}
.form-group .input-group input:focus {
  outline: 0;
  box-shadow: none;
}
.form-group .input-group input {
  background: transparent;
  border: 0;
  font-size: 15px;
  padding-left: 30px;
  height: 45px;
  color: #606060;
}
.form-group .input-group .input-group-addon {
  background: none;
  border: none;
  padding: 0;
}
.form-group .input-group .input-group-addon:active,
.form-group .input-group .input-group-addon:focus {
  box-shadow: none !important;
  outline: 0;
}
#coinage-subscribe {
  border: 0;
  background-image: linear-gradient(
    90deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  color: #ffffff !important;
  font-size: 16px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-weight: 300;
  font-family: 'Source Serif Pro', serif;
  margin-right: -10px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 0 50px 50px 0;
}

.form-group .input-group .input-group-addon .mc-button.btn:active,
.form-group .input-group .input-group-addon .mc-button.btn:focus {
  outline: 0;
}

.footer-bottom {
  /* background: #150108; */
  text-align: center;
  margin-top: 0px;
}
.copyright p {
  padding: 15px 0;
  color: #d5d4d5 !important;
  margin-bottom: 0;
  font-size: 16px;
}
@media all and (max-width: 768px) {
  .footer-section {
    padding-top: 10px;
  }
  .footer-bottom {
    margin-top: 0px;
  }
}

/* ==================================================================
   22.0 map section styles
================================================================== */
.roadmap-section {
  padding-top: 150px;
  margin-bottom: 82px;
  overflow: hidden;
}
.mbg-red {
  background: #fa4578;
}
.mbg-gray {
  background: #9bb4cf;
}
.mbg-gray:after {
  border-color: #9bb4cf !important;
}
.mbg-black {
  background: #3f5267;
}
.mbg-black:after {
  border-color: #3f5267 !important;
}
.mbg-yellow {
  background: #ffca28;
}
.mbg-yellow:after {
  border-color: #ffca28 !important;
}
.single-map {
  width: 16.4%;
  float: left;
  margin: 0 0.13%;
  margin-bottom: 70px;
}
.single-map .icons {
  border: 1px solid;
  border-color: rgb(228, 228, 228);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: inline-block;
  line-height: 65px;
  margin-bottom: 100px;
  position: relative;
}
.single-map .bars {
  height: 10px;
  width: 100%;
}
.single-map .details .title {
  font-size: 18px;
  font-weight: 700;
}
.single-map .icons:after {
  content: '';
  height: 40px;
  width: 1px;
  position: absolute;
  left: 50%;
  background: rgb(228, 228, 228);
  top: 90px;
}
.single-map .bars {
  position: relative;
}
.single-map .bars:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 9px solid transparent !important;
  border-right: 9px solid transparent !important;
  border-bottom: 15px solid #fa4578;
  top: -13px;
  left: 50%;
  margin-left: -9px;
}
.single-map .details {
  margin-top: 38px;
}
.single-map-2 {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  margin-top: 35px;
}
.single-map-2 .details {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}
.single-map-2 .icons img {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
}
@media all and (max-width: 768px) {
  .single-map {
    width: 49.5%;
  }
  .single-map .icons {
    margin-bottom: 74px;
  }
  .single-map .icons:after {
    top: 80px;
  }
}
@media all and (max-width: 480px) {
  .single-map .details {
    margin-top: 12px;
  }
}
/* Tabs */
.ui-tabs {
  position: inherit;
  border: 0 !important;
}
.ui-tabs .ui-tabs-nav {
  background: 0;
  border: 0;
  border-radius: 0 !important;
}
.pricing-tabs {
  text-align: center;
}
.nav.pricing-tabs-list {
  display: inline-flex;
  padding: 0 !important;
  border-bottom: 0;
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.1);
  margin-bottom: 37px !important;
}
.pricing-tabs .nav.pricing-tabs-list a {
  margin: 0;
  border: 0;
  font-size: 18px;
  line-height: 22px;
  color: #ff4484 !important;
  border-radius: 0 !important;
  height: 40px !important;
  border-right: 1px solid #ffc828;
  background: #fff;
}
.pricing-tabs .nav.pricing-tabs-list a:hover {
  border-right: 1px solid #ffc828;
}
.pricing-tabs .nav.pricing-tabs-list a svg {
  width: 18px;
  padding-right: 8px;
}
.pricing-tabs .nav.pricing-tabs-list a.active {
  background: #ffc828;
  color: #ffffff !important;
}
.pricing-tabs .nav.pricing-tabs-list a:first-child {
  border-radius: 4px 0 0 4px !important;
}
.pricing-tabs .nav.pricing-tabs-list a:last-child {
  border: none;
  border-radius: 0 4px 4px 0 !important;
}

.pricing-tabs .nav.pricing-tabs-list a:focus {
  outline: 0 !important;
  border-radius: 0 !important;
  height: 40px !important;
}
@media all and (max-width: 768px) {
  .pricing-tabs .nav.pricing-tabs-list a {
    font-size: 14px;
  }
  .pricing-tabs .nav.pricing-tabs-list a.nav-link {
    padding-right: 10px !important;
    padding-left: 10px !important;
  }
}
/* ==================================================================
   12.0 Scroll to Top
================================================================== */
#scrollUp {
  background-image: -ms-linear-gradient(
    0deg,
    rgb(255, 182, 40) 0%,
    rgb(249, 62, 126) 100%
  );
  border-radius: 4px;
  bottom: 60px;
  color: #fff;
  bottom: 50px;
  color: #fff;
  height: 48px;
  width: 48px;
  right: 20px;
  text-align: center;
  padding-top: 11px;
}
#scrollUp .fa {
  font-size: 24px;
  color: #ffffff;
}
.marquee {
  position: relative;
  overflow: hidden;
}
.marquee ul li {
  position: absolute;
  list-style: none;
}
.marquee ul li img {
  margin-top: -4px;
  height: 22px;
  width: 22px;
  margin-right: 4px;
}
.breaking-news {
  background: #3f5267;
  overflow: hidden;
  white-space: nowrap;
  color: #fff;
  padding: 11px 0 19px 0;
}
.breaking-news i {
  font-size: 18px;
  padding: 0 5px 0 12px;
}
.fixed-header {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 20;
  box-shadow: 0px 0px 20px 0px rgba(249, 69, 121, 0.15);
  transition: all 0.8s ease;
}
.navbar.navbar-default.fixed-header {
  top: 0;
}

/* ==================================================================
   23.0 home 2 styles
================================================================== */
/* .banner-area-2 {
  background: url('../images/bg/06.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 230px 0 100px 0;
} */

.banner-right {
  position: relative;
  z-index: 1;
}

.register-form {
  background: #ffffff;
  padding: 67px 30px 70px 30px;
  margin-top: 11px;
  box-shadow: 1.5px 3.99px 27px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.register-form .title {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
}
.register-form .sub-title {
  color: #fff;
  font-size: 24px;
  margin-bottom: 42px;
}
.register-form .form-control {
  height: 45px;
  border: 0;
  border-left: 4px solid #606060;
  margin-bottom: 25px;
  box-shadow: 0.5px 0.866px 10px 0px rgba(0, 0, 0, 0.1);
}
.register-form .form-control:focus {
  border-color: none !important;
  box-shadow: none !important;
}
register-form .form-control.error {
  border-left-color: #ff4081 !important;
}
.register-form .btn {
  margin-top: 15px;
}
@media all and (max-width: 768px) {
  .banner-area-2 {
    text-align: center;
  }
  .banner-area-2 .register-form {
    margin-top: 50px;
    background-position: initial;
  }
}
/* Breaking News */
.breaking-news-area {
  margin-top: -263px;
  margin-bottom: 122px;
}
.single-news {
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  font-family: 'PT Sans', sans-serif;
  padding: 27px 5px 30px 5px;
  border-radius: 4px;
  background: #fff;
  margin: 15px;
}
.single-news .title-wrap {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 13px;
  display: inline-flex;
}
.single-news .title-wrap .icons {
  margin-right: 8px;
}
.single-news .title-wrap .title span {
  font-weight: 400;
}
.single-news .sub-title {
  margin-bottom: 16px;
  font-size: 14px;
  color: #20c93b;
}
.single-news .sub-title-2 {
  color: #ff4081;
}
.single-news .sub-title i {
  margin-left: 6px;
}
.single-news .btn {
  padding: 10px 34px;
}

.section-cryptocarousel {
  overflow: hidden;
  padding: 30px 0;
}
.crypto-carousel .exchange-rate-single {
  margin: 15px;
}
.exchange-rate-single {
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  font-family: 'PT Sans', sans-serif;
  padding: 27px 5px 30px 5px;
  border-radius: 4px;
  background: #fff;
}
.exchange-rate-single .title-wrap {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 13px;
  display: inline-flex;
}
.exchange-rate-single .title-wrap .icons {
  margin-right: 8px;
}
.exchange-rate-single .title-wrap .title span {
  font-weight: 400;
}
.exchange-rate-single .sub-title {
  margin-bottom: 16px;
  font-size: 14px;
  color: #20c93b;
}
.exchange-rate-single .sub-title-2 {
  color: #ff4081;
}
.exchange-rate-single .sub-title i {
  margin-left: 6px;
}
.exchange-rate-single .btn {
  padding: 10px 34px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Custom style */

.logo-top {
  width: 100%;
}

.btn.btn-join {
  background-color: #fff;
  color: #e34d01;
  padding: 10px;
  border-radius: 10px;
  width: 220px;
  border: none;
  font-weight: 500;
  font-size: 20px;
  box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.15) !important;
  z-index: 2;
}

.btn.btn-join:hover {
  color: #e34d01;
}

.btn.btn-white-paper {
  background-color: #da4400;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  width: 220px;
  border: none;
  font-size: 20px;
  box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.15) !important;
  z-index: 2;
}

.btn.btn-white-paper:hover {
  color: #fff;
}

.btn.btn-buy {
  background-color: #fd8853;
  color: #fff;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 600;
  border: none;
}

.btn.btn-buy:hover {
  color: #fff;
}

.rate-label {
  box-shadow: 1.5px 3.99px 27px 0px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.label-exchange {
  color: #85cf3b;
  font-size: 14px;
  font-weight: 500;
}

.price {
  color: #ff4081;
  padding: 15px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 500;
}

.about-kgr {
  color: #fe924c;
}

.about-yen {
  color: #00b4ff;
}

.description {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}

.single-element .el-header {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feature-section .description {
  line-height: 25px;
}

.feature-section .entry-title {
  color: #000cff;
}

.allocation-area .entry-title {
  color: #00b4ff;
  line-height: 40px;
  font-weight: 600;
  font-size: 38px;
}

.allocation-area {
  padding-top: 100px;
}

.community-area .title {
  font-weight: 600;
  margin-top: 10px;
}

.community-area .element {
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100px;
  cursor: pointer;
}

.community-area .list-element {
  background-color: #f8fafb;
  display: flex;
}

.community-area .element:hover {
  background-color: #eff2f4;
}

.community-area {
  padding-top: 100px;
  padding-bottom: 100px;
}

.nav-item-active {
  color: rgb(249, 62, 126) !important;
}
.bird-logo {
  width: 130px;
  margin-right: 10px;
}

.token-description {
  font-size: 28px;
  font-weight: 500;
  margin-top: 80px;
}
.dropbtn {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5) !important;
  text-transform: none !important;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 200px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);
}
.dropdown-content::after {
  content: ' ';
  position: absolute;
  left: 20px;
  top: -15px;
  border-top: none;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 15px solid white;
}

.dropdown-content .learn-item {
  color: black !important;
  text-decoration: none;
  display: block !important;
  width: 100%;
  max-height: 40px !important;
  line-height: 30px !important;
}

.dropdown-content .learn-item:hover {
  background-color: #000;
  color: #fff !important;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #fff;
  color: rgb(249, 62, 126) !important;
}
.content {
  min-height: calc(100vh);
  background-color: #fff;
  font-family: 'Noto Sans',sans-serif !important;
}
.input-mail-sub .MuiOutlinedInput-root {
  border-radius: 40px;
  background-color: #fff;
}

.contentModal {
  position: absolute;
  max-width: 650px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.contentModal.max500 {
  max-width: 500px;
}
.header {
  background-color: #434f61;
  padding: 20px 10px;
  border: 0;
  display: grid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  grid-template-columns: 1fr 25px;
}
.modalTitle {
  font-size: 1.4rem;
  padding: 0 15px;
  color: #fff;
}
.paperModal {
  padding: 24px;
}
@media all and (max-width: 768px) {
  .contentModal {
    max-width: 95% !important;
  }
}

