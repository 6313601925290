.contentModal {
  position: absolute;
  max-width: 650px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.contentModal.max500 {
  max-width: 500px;
}
.header {
  background-color: #434f61;
  padding: 20px 10px;
  border: 0;
  display: grid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  grid-template-columns: 1fr 25px;
}
.modalTitle {
  font-size: 1.4rem;
  padding: 0 15px;
  color: #fff;
}
.paperModal {
  padding: 24px;
}
@media all and (max-width: 768px) {
  .contentModal {
    max-width: 95% !important;
  }
}
