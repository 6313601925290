.padding-default {
  padding: 150px 0;
}
.pd-top-20 {
  padding-top: 20px;
}
.pd-top-37 {
  padding-top: 37px;
}
.pd-top-40 {
  padding-top: 40px;
}
.pd-top-95 {
  padding-top: 95px;
}
.pd-top-100 {
  padding-top: 100px;
}
.pd-top-150 {
  padding-top: 150px;
}
.pd-bottom-70 {
  padding-bottom: 70px;
}
.pd-bottom-95 {
  padding-bottom: 95px;
}
.pd-bottom-100 {
  padding-bottom: 100px;
}
.pd-bottom-150 {
  padding-bottom: 150px;
}
.mg-top-100 {
  margin-top: 100px;
}
.mg-bottom-70 {
  margin-bottom: 70px;
}
.mg-bottom-50 {
  margin-bottom: 50px;
}
.mg-bottom-100 {
  margin-bottom: 100px;
}
.mg-bottom-150 {
  margin-bottom: 150px;
}
.mg-top-129 {
  margin-top: 129px;
}
.mg-top-89 {
  margin-top: 89px;
}

.mg-bottom-55 {
  margin-bottom: 55px !important;
}
